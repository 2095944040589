.modal-90w {
  width: 90% !important;
  max-width: 750px !important;
}

@media (max-width: 768px) {
  .modal-90w {
    width: 100% !important;
  }
}
