footer {
  position: relative;
  right: 0;
  left: 0;
  bottom: 0;

  background: #f5f1ee;
  color: #000;
  width: 100%;
  font-size: 20px;
  margin-top: 40px;
  padding: 20px 0;
  border-top: 1px solid #e7e7e7;
}

@media (max-width: 768px) {
  footer {
    position: relative;
  }
}

/* footer .container {
}
footer .row {
  color: black;
  gap: 40px;
}

footer .col {
  color: black;
  padding: 0 10px;
  font-size: 20px;
} */

footer a {
  color: #000;
  text-decoration: none;
  font-size: 20px;
}

footer {
  clear: both;
  background: #efefef;
  color: #000;
  padding: 10px;
  border-top: 1px solid #a7ce39;
  right: 0;
  bottom: 0px;
  left: 0;
  padding: 1rem;
  text-align: center;
  width: 100%;
  font-size: 14px;
}

footer a {
  color: #000;
  text-decoration: none;
  font-size: 14px;
}
footer .text-left {
  text-align: left;
}
