nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  padding: 13px 20px;
  border-bottom: 1px solid #e7e7e7;
}
nav ul li a {
  color: #000;
  font-size: 16px;
  font-weight: 400;
}

nav ul li.active {
  color: #000;
  background: #efeaf3;
}
nav ul li.active a {
  font-weight: bold;
}
