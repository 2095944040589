div.navbar-toggler {
  margin-top: 16px;
  margin-bottom: 16px;
}
.dropdown-menu.show {
  border: 0px;
}

menu {
  /* background-color: #ccc;
  border-bottom: 4px solid #a7ce39;
  padding: 0 !important;
  margin: 0 !important; */
}

menu nav {
  /* background-color: #6aa13c;
  border-bottom: 40px solid #a7ce39; */
}

nav .container {
  background-color: #6aa13c;
  border-bottom: 4px solid #a7ce39;
  padding: 8px;
  margin-bottom: 20px;
}

.bg-body-tertiary.mb-3 {
  padding: 0 !important;
  margin: 0 !important;
}

.navbar-toggler {
  color: white;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.6);
}
.navbar-toggler:visited {
  color: white;
  border: 0px solid;
  border-color: rgba(255, 255, 255, 0.6);
}

/* Set the border color to the desired color */
.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.menuExtra a {
  color: white !important;
  font-size: 16px;
  font-weight: 600;
  padding: 4px;
  padding-right: 16px;
  margin-left: 20px;
  text-decoration: none;
}

.menuExtra a:hover {
  color: #f0f0f0;
  text-decoration: none;
}

@media (max-width: 768px) {
  .menuExtra {
    display: none;
  }
}
