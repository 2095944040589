.container-lg {
  margin: 0;
  padding: 0;
  column-gap: 20px;
}

.container-lg .col {
  margin: 0;
  margin-left: 6px;
  margin-right: 6px;
  padding: 0;
}

.openPositionsList {
  row-gap: 16px;
  column-gap: 4px;
}
