body {
  font-size: 14px;
}
a,
a:link {
  color: #13b5ea;
  text-decoration: none;
  /* font-weight: 600; */
}
a:hover {
  text-decoration: underline;
}
ol,
ul {
  /* list-style: none; */
  margin-left: 43px;
}
.container {
  max-width: 915px;
}
article {
  scroll-behavior: smooth;
  font-family: Verdana, "Open Sans", "Open Bold", Arial;
}

.breadcrumbs {
  margin-bottom: 30px !important;
}
.breadcrumbs a {
  text-decoration: none;
  color: #999999;
}
.navbar {
  background-color: transparent !important;
  margin-bottom: 0px !important;
}

.navbarRow {
  background-color: white;
  margin-top: 8px;
  padding: 12px;
  font-size: 16px;
}

aside {
  margin-bottom: 24px;
}
aside ul li a {
  text-decoration: none;
  font-size: 14px;
}
aside ul li a:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  article .col-md-4 {
    /* display: none; */
    order: 4;
  }
}

menu {
  border-top: 1px solid rgb(231, 231, 231);
  border-bottom: 1px solid rgb(231, 231, 231);
  margin-bottom: 24px;
}
menu a {
  color: black;
  text-decoration: none;
}

@media (min-width: 768px) {
  menu {
    font-size: 24px;
  }

  menu a,
  menu a:hover,
  menu a:active,
  menu a:focus {
    color: black;
  }
}

.offcanvas,
.offcanvas a {
  color: black;
}

.offcanvas-body .nav-link,
.offcanvas-body .navbar-toggler {
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 0.25rem 0.75rem;
}

.offcanvas-body .nav-link:hover,
.offcanvas-body .navbar-toggler:hover {
  background-color: #f9f9f9;
}

.offcanvas-body .dropdown-item:hover {
  background-color: transparent;
}

.offcanvas-header a,
.offcanvas-body a {
  text-decoration: none;
}
.offcanvas-header a:hover,
.offcanvas-body a:hover {
  text-decoration: underline;
}
.offcanvas-body a {
  font-size: 14px;
}
article .row .col-md-8 {
  border: 0px solid rgb(231, 0, 231);
  height: 100%;
}
article .row .col-md-8 .row {
  border: 0px solid rgb(231, 0, 231);
  height: 100%;
  gap: 16px 8px;
}
article .col {
  border: 0px solid rgb(0, 0, 231);
}

menu {
  margin: 0;
  padding: 0;
}

footer {
  font-family: Verdana;
}

.imgErfarenhetsutbyte {
  max-width: 200px;
  margin-right: 20px;
}
