.arkivlista p,
.jobb p,
.vanligsida p,
.medarbetarecontentleft p,
.nyhet p {
  padding: 5px 0;
}

.vanligsida li {
  list-style-type: disc;
}

.streamio-player {
  width: 560px;
  height: 315px;
}

.imageInspirationsforelasning {
  max-width: 100%;
}

.pdfDokument {
  width: 100%;
  height: 330px;
}

@media only screen and (max-width: 878px) {
  .streamio-player {
    width: 100%;
    height: 100%;
  }

  .pdfDokument {
    width: 100%;
  }
  #input {
  }
}
