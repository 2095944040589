header .container {
  border: 0px solid red;

  display: flex;
  justify-content: space-evenly;

  padding: 10px 0 0 0;
  align-items: center;

  /* align-items: flex-end;
  padding: 24px 0 24px; */
}

header a,
header .header-title a,
header .header-title a:hover,
header .header-title a:focus,
header .header-title a:active {
  color: #000 !important;
  text-decoration: none !important;
}

header a.headerText {
  color: #000;
  text-decoration: none;
}

header .header-title {
  /* border: 1px solid cyan; */
  margin: 0;
  font-size: 48px;
  width: 100%;
  float: left;
  font-weight: 500;
  line-height: 62px;
  text-decoration: none;
}

@media (max-width: 768px) {
  header a {
    padding: 0px;
    font-size: 14px;
    line-height: 0.9;
    color: #000;
  }
  header img {
    width: 30%;
    height: 30%;
    padding-right: 10px;
  }
  header .header-title {
    /* border: 1px solid cyan; */
    margin: 0;
    font-size: 18px;
    width: 100%;
    float: left;
    font-weight: 500;
    line-height: 62px;
    text-decoration: none;
  }
  header a.headerText {
    color: #000;
    font-size: 12px;
    text-decoration: none;
  }
  header .container {
    border: 0px solid red;

    display: flex;
    justify-content: center;

    padding: 10px 0 0 0;
    align-items: center;
  }
}
