/* Todo: Placera*/
.crumbs {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  margin-bottom: 0px !important;
  border: 0px;
}

.breadcrumbsBorders {
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  padding: 15px 0;
  margin-bottom: 25px;
}
