.card {
  height: 100%;
  min-width: 200px;
  /* cursor: pointer; */
}
.card img {
  /* height: 300px; */
}
.card .readMore {
  color: #13b5ea;

  text-decoration: underline;
  cursor: pointer;
}

.card a {
  color: #13b5ea;
  text-decoration: underline;
  cursor: pointer;
}
