.card {
  height: 100%;
  /* cursor: pointer; */
  margin-bottom: 16px;
}
.card img {
  /* height: 300px; */
}
.openPosition {
  margin-top: 20px;
}
.openPosition ul {
  list-style: disc;
  margin-left: 28px;
}

.newslistpublisheddate {
  font-size: 12px;
  font-family: Verdana;
  color: darkgray;
}
